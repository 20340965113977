import React, { useEffect, useState } from "react";
import { observer, inject } from "mobx-react";
import { Link } from "react-router-dom";

// Components
import {
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Row,
  Col,
} from "reactstrap";

// Styles
import styles from "./signup.module.sass";

const SignUpPage: React.FC = inject(
  "routing",
  "UserStore",
  "FormsStore",
  "LocalesStore",
  "RolesStore"
)(
  observer(
    ({ routing, UserStore, FormsStore, LocalesStore, RolesStore }: any) => {
      const [agree, setAgree] = useState(false);
      const { signUpForm } = FormsStore;
      const { locale } = LocalesStore;
      useEffect(() => {
        if (UserStore.loggedIn) routing.replace("/");
      }, [UserStore.loggedIn]);
      return (
        <div className={styles.signup}>
          <Card className={styles.container}>
            <CardBody>
              <h2>{locale.signUpForm.header}</h2>
              <hr />
              <Form
                className={styles.signupForm}
                onSubmit={(e) => signUpForm.onSubmit(e)}
              >
                {signUpForm.fields.map((field) => (
                  <div>
                    {field.type === "radio" && [
                      <Row>
                        <Col>
                          <FormGroup check>
                            <Label check>
                              <Input
                                type="radio"
                                name={field.name}
                                value={RolesStore.findByName("customer")?.id}
                                checked={
                                  field.value ===
                                  RolesStore.findByName("customer")?.id
                                }
                                onChange={(e) =>
                                  field.onChange(
                                    RolesStore.findByName("customer").id
                                  )
                                }
                              />{" "}
                              {locale.roles?.customer}
                            </Label>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup check>
                            <Label check>
                              <Input
                                type="radio"
                                name={field.name}
                                value={
                                  RolesStore.findByName("tradinghouse")?.id
                                }
                                checked={
                                  field.value ===
                                  RolesStore.findByName("tradinghouse")?.id
                                }
                                onChange={(e) =>
                                  field.onChange(
                                    RolesStore.findByName("tradinghouse")?.id
                                  )
                                }
                              />{" "}
                              {locale.roles?.tradinghouse}
                            </Label>
                          </FormGroup>
                        </Col>
                      </Row>,
                      <hr />,
                    ]}
                    {["email", "password"].includes(field.type) && (
                      <FormGroup>
                        <Label for={field.name}>
                          {field.label(
                            locale.signUpForm.fields[field.name].label
                          )}
                        </Label>
                        <Input
                          type={field.type}
                          name={field.name}
                          placeholder={field.label(
                            locale.signUpForm.fields[field.name].placeholder
                          )}
                          value={field.value}
                          onChange={(e) => field.onChange(e)}
                          className={!field.valid ? "invalid" : ""}
                        />
                      </FormGroup>
                    )}
                    {field.message && (
                      <p
                        className="fieldMessage"
                        data-type={field.message.type}
                      >
                        {field.message.content}
                      </p>
                    )}
                  </div>
                ))}
                <div className={styles.submit}>
                  {/* <Button color="primary" size="lg" disabled={!agree}>
                    {locale.signUpForm.signUpButton}
                  </Button> */}
                  <p>{locale.signUpForm.betatest}</p>
                </div>
              </Form>
              <hr />
              {/* <div className={styles.agreement}>
                <div>
                  <FormGroup check inline>
                    <Input
                      type="checkbox"
                      checked={agree}
                      onChange={(e) =>{
                        console.log(e.currentTarget);
                        e.currentTarget.checked
                          ? setAgree(true)
                          : setAgree(false)
                      }}
                    />
                    <Label check>{locale.signUpForm.agreed}</Label>
                  </FormGroup>
                </div>
                <span>
                  {locale.signUpForm.conditions1}{" "}
                  <a href="/UserAgreement_ru.pdf" target="_blank">
                    {locale.signUpForm.conditionsTermsOfServiceLink}
                  </a>
                  . {locale.signUpForm.conditions3}
                </span>
              </div> */}
            </CardBody>
          </Card>
        </div>
      );
    }
  )
);

export default SignUpPage;
